.widget-recents--noSpacesWrapper--2Ncs6 {
  height: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: black;
}

.widget-recents--noSpacesImg--2mdc1 {
  height: 72px;
  width: 100%;
  margin-bottom: 24px;
}

.widget-recents--noSpacesTitle--1has3 {
  color: #fff;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 12px;
}

.widget-recents--noSpacesMessage--2dmAb {
  color: hsla(0,0%,100%,.6);
  line-height: 22px;
  text-align: center;
}
