.widget-recents--recentsWidget--1HEut {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  /* IE 11 scrollbar styling workarounds */
  scrollbar-face-color: #8a8a8a;
  scrollbar-shadow-color:black;
  scrollbar-track-color: black;
  scrollbar-shadow-color: black;
}

.widget-recents--spacesListWrapper--2YlL5 {
  height: 100%;
  width: 100%;
  overflow: auto;
  font-size: 16px;
  background: #fff;
}

.widget-recents--errorWrapper--2rsUO {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.widget-recents--loadMoreContainer--pNfOD {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px 0;
}

.widget-recents--loadMoreButton--31Z49 {
  height: 36px;
  min-width: 64.8px;
  padding: 0 36px;
  font-size: 14px;
  line-height: 24px;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #fff;
  cursor: pointer;
  background: #07c1e4;
  border: 1px #07c1e4;
  border-radius: 4px;
}

.widget-recents--midDot--2CUEG::after {
  padding: 0 3px;
  content: '\b7';
}
