.widget-recents--recentsHeader--1nZrH {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: black;
}

.widget-recents--bottomBorder--1bQHQ {
  border-bottom: 1px solid #666666;
}

.widget-recents--searchInputWrapper--2DVrO {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  margin: auto;
  fill: #efefef;
  background-color: black
}

.widget-recents--searchInput--1C6GZ {
  background-color: #666666;
  border-radius: 100px;
  width: 100%;
  height: 32px;
}

.widget-recents--searchInput--1C6GZ input {
  border: none;
}

.widget-recents--headerSideItem--Doe9E {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin-left: 14px;
  margin-right: 14px;
  min-width: 40px;
  height: 40px;
}
